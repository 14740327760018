
import React, { useState, useEffect, useRef } from "react";

import { Shimmer, Breathing } from 'react-shimmer'
import axios from 'axios';
import Chart from 'react-apexcharts'
import { CgNotes } from "react-icons/cg";


import {
  CCol,
  CRow,
  CContainer,
} from '@coreui/react'


const VulnerabilityTrends = () => {

  const [loadingVulnTrends, setLoadingVulnTrends] = useState(false)

  const [last5DaysVulnTrends, setLast5DaysVulnTrends] = useState([1, 2, 3, 4, 5])

  const [vulnerabilityDistribution, setVulnerabilityDistribution] = useState({})
  const [loadingVulnerabilityDistribution, setLoadingVulnerabilityDistribution] = useState(false)

  const [months, setMonths] = useState([])
  const [vulnCounts, setVulnCounts] = useState([])

  useEffect(() => {

    //getLast5DaysVulnerabilityTrends();
    fetchVulnerabilityDistribution();
    fetchVulnerabilityDistribution1();

  }, []);


  const fetchVulnerabilityDistribution = () => {

   


    const endpoint = 'api/v1/users/getMonthlyVulnerabilityTrends';
    const token = localStorage.getItem('ASIToken');

    axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {


        setMonths(response.data.activeScansByMonth);

        // Save into local storage to show from cache while it loads next time
      //  localStorage.setItem('vulnerabilityCounts', JSON.stringify(response.data.activeScansByMonth));

        setLoadingVulnerabilityDistribution(false)
      })
      .catch(error => {
        //console.error('Error fetching dashboard data:', error);
        setLoadingVulnerabilityDistribution(false)
      });
  };


  const fetchVulnerabilityDistribution1 = () => {

    // Set from localStorage cache
   /* if (localStorage.getItem('vulnerabilityCounts')) {
      console.log('if')
      setVulnerabilityDistribution(JSON.parse(localStorage.getItem('vulnerabilityCounts')));
    } else {
      console.log('else')
      setLoadingVulnerabilityDistribution(true);
    }*/


    const endpoint = 'api/v1/users/getVulnerabilityDistribution';
    const token = localStorage.getItem('ASIToken');

    axios.get(endpoint, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {


        setVulnCounts(response.data.vulnerabilityDistribution.vulnCounts);

        // Save into local storage to show from cache while it loads next time
     //   localStorage.setItem('vulnerabilityCounts', JSON.stringify(response.data.vulnerabilityDistribution));

        setLoadingVulnerabilityDistribution(false)
      })
      .catch(error => {
        //console.error('Error fetching dashboard data:', error);
        setLoadingVulnerabilityDistribution(false)
      });
  };

  
  console.log('months:', months)




  const vulnLabels = [
    "Broken Object Level Authorization",
    "Sensitive Data in Path Params",
    "Basic Authentication Detected",
    "Endpoint Not Secured by SSL",
    "Unauthenticated Endpoint Returning Sensitive Data",
    "Sensitive Data in Query Params",
    "PII Data Detected in Response",
    "HTTP Verb Tampering Possible",
    "Content Type Injection Possible",
    "Security Headers Not Enabled on Host",
    "Resource Deletion Possible",
    "Broken Authentication",
    "Excessive Data Exposure",
    "Injection Possible",
    "XSS Vulnerability Found",
    "Wallet Hijacking Possible",
    "Pre Image Attack Possible",
    "Lack of Resource & Rate Limiting",
  ];





  var lineChartSeries = [];

  

// Get the list of months in order
const monthLabels = months.map(month => Object.keys(month)[0]);

console.log('monthLabels:',monthLabels)

// Initialize an empty array for the line chart series

// Loop through each vulnerability index
for (let vulnIndex = 0; vulnIndex < vulnLabels.length; vulnIndex++) {
  // Create an array to store counts for each month
  const seriesData = monthLabels.map(monthLabel => {
      // Find the month object
      const monthObj = months.find(month => Object.keys(month)[0] === monthLabel);
      // Return the count for the current vulnerability index
      return monthObj ? monthObj[monthLabel][`vuln${vulnIndex + 1}Count`] : 0;
  });

  // Add the series data to the chart series
  lineChartSeries.push({
      name: vulnLabels[vulnIndex],
      data: seriesData,
  });
}

  // Creating the chart options
  var lineChartOptions = {

    chart: {
      type: 'line',
      height: 350,
      animations: {
        enabled: true,
        easing: 'linear',
        dynamicAnimation: {
          speed: 1000
        }
      }
    },
    xaxis: {
      categories: monthLabels,
      labels: {
        style: {
          colors: '#5D596C'
        }
      }
    },
    yaxis: {
      labels: {
        style: {
          colors: '#5D596C'
        }
      }
    },
    series: lineChartSeries,
    legend: {
      labels: {
        colors: '#5D596C'
      },
      containerMargin: {
        top: 20
      }
    },
    tooltip: {
      theme: 'light',
      style: {
        color: '#000000'
      }
    }
  };



  console.log('lineChartSeries:', lineChartSeries)


  return (


    <div className="theCards" style={{
      display: 'flex', flexDirection: 'column', borderRadius: 10, padding: 20, marginTop: 20,
      width: '49%',
    }}>


      <div
        style={{}}>

        <span style={{ color: '#5D596C', textAlign: 'left', marginTop: 10, fontSize: 18, display: 'block' }}>Vulnerability Trends</span>
        <span style={{ fontSize: 13, textAlign: 'left', alignSelf: 'left', color: 'rgb(165, 163, 174', display: 'block' }}>
          Discovery trend of vulnerabilities for last 12 months</span>
        <hr style={{ borderColor: 'white' }} />


        {loadingVulnTrends ?

          <div style={{ display: 'flex', flexDirection: 'column', height: 300, justifyContent: 'space-between' }}>
            <Breathing width={'100%'} height={10} style={{ borderRadius: 150, alignSelf: 'center', }} />
            <Breathing width={'80%'} height={10} style={{ borderRadius: 150, alignSelf: 'center', marginTop: 5 }} />
            <Breathing width={'80%'} height={10} style={{ borderRadius: 150, alignSelf: 'center', marginTop: 5 }} />
            <Breathing width={'70%'} height={10} style={{ borderRadius: 150, alignSelf: 'center', marginTop: 5 }} />
            <Breathing width={'60%'} height={10} style={{ borderRadius: 150, alignSelf: 'center', marginTop: 5 }} />
            <Breathing width={'50%'} height={10} style={{ borderRadius: 150, alignSelf: 'center', marginTop: 5 }} />
            <Breathing width={'70%'} height={10} style={{ borderRadius: 150, alignSelf: 'center', marginTop: 5 }} />
            <Breathing width={'60%'} height={10} style={{ borderRadius: 150, alignSelf: 'center', marginTop: 5 }} />
            <Breathing width={'50%'} height={10} style={{ borderRadius: 150, alignSelf: 'center', marginTop: 5 }} />
            <Breathing width={'70%'} height={10} style={{ borderRadius: 150, alignSelf: 'center', marginTop: 5 }} />
            <Breathing width={'60%'} height={10} style={{ borderRadius: 150, alignSelf: 'center', marginTop: 5 }} />
            <Breathing width={'50%'} height={10} style={{ borderRadius: 150, alignSelf: 'center', marginTop: 5 }} />
          </div>

          :

          <>

            {lineChartSeries && lineChartSeries.length > 0 && months.length > 0 
              
              ?


              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '60vh', // Use viewport height for the container
                }}>

                <div style={{ flex: 1, minWidth: 0, marginTop: 100 }}>


                  <Chart options={lineChartOptions} series={lineChartSeries} type="line" width="100%" height="100%"
                  />


                </div>

              </div>

              :

              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 30, }}>
                <CgNotes size={40} style={{ color: '#f73164', textAlign: 'center' }} />
                <text style={{ textAlign: 'center', color: '#f73164', marginTop: 20, fontSize: 13 }}>No Data Yet</text>
              </div>

            }

          </>
        }

      </div>
    </div>



  )

}

export default VulnerabilityTrends